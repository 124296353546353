

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL4PostLabPart3',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part4_3: null,
      },
      questions: [
        {
          text: "What operations could you perform to help with Mr. Humphrey's problem?",
          inputName: 'part4_3',
        },
      ],
    };
  },
};
